import { useEffect, useState } from 'react';
import { isMobile } from '../utils/userAgent'
import femaleIcon from '../images/female-icon-1.png'
import maleIcon from '../images/male-icon-1.png'
import hornyIcon from '../images/male-icon-2.png'
import nonBinaryGenderIcon from '../images/non-binary-gender-icon.png'
import idMap from '../constants/idMap.json'
let myMap = new Map(Object.entries(idMap));
export interface Props {
    onClick: (index: any) => void;
    tokenId: number;
    holdingTime: number;
    tokenUri: string;
    chickenType: GenderType;
    isInCoolDown: boolean;
    isEnergy?: boolean;
    sexEndTime?: number;
}

// 0 母 1 公 2 中性 3 旺盛
// 缺：3 旺盛
export enum GenderType {
    FEMALE = 0,
    MALE = 1,
    NON_BINARY_GENDER = 2,
    HORNY = 3

}

export enum StatusType {
    COOL = 'cool',
    SEX = 'sex',
    ENERGY = 'energy'
}

export const genderIconMap: Record<GenderType, any> = {
    [GenderType.MALE]: maleIcon,
    [GenderType.FEMALE]: femaleIcon,
    [GenderType.NON_BINARY_GENDER]: nonBinaryGenderIcon,
    [GenderType.HORNY]: hornyIcon

}

export interface TimeLeft {
    days: string,
    hours: string,
    minutes: string,
    seconds: string
}

const ChickenCard = (props: Props) => {

    const [image, setImage] = useState("");
    const [tokenId, setTokenId] = useState<string | undefined>("");
    useEffect(() => {
        const fetchData = async () => {
            setImage((await import(`../assets/images/${props.tokenId}.png`)).default);
        }
        fetchData()
        setTokenId(myMap.get(props.tokenId.toString()))
    }, [props.tokenId]);
    const calculateTimeLeft = (): TimeLeft | null => {
        const endTime: number = props.sexEndTime || 0
        let difference = endTime - Math.floor(Date.now() / 1000);
        if (difference <= 0) return null
        return {
            days: Math.floor(difference / (60 * 60 * 24)).toString(),
            hours: Math.floor((difference / (60 * 60)) % 24).toString(),
            minutes: Math.floor((difference / 60) % 60).toString(),
            seconds: Math.floor((difference) % 60).toString()
        };
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div style={{ margin: '0 auto', maxWidth: isMobile ? '100%' : '346px', background: '#fff', width: '100%', border: '2px solid #939393', borderRadius: '10px' }} onClick={props.onClick}>
            <div style={{ borderRadius: '10px', overflow: 'hidden', margin: '-2px -2px 0 -2px', position: 'relative' }}>
                <img src={image} alt="" style={{ maxWidth: '100%' }} />
                {
                    !props.isInCoolDown && !props.isEnergy && typeof props.sexEndTime === 'number' && props.sexEndTime > Math.floor(Date.now() / 1000) &&
                    <div style={{ position: 'absolute', bottom: '14px', left: '0', width: '100%', display: 'flex', justifyContent: 'space-between', padding: isMobile ? '0 5px' : '0 10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ fontSize: isMobile ? '8px' : '12px', display: 'inline-block', width: isMobile ? 'auto' : '40px', textAlign: 'center', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', borderBottom: 'none', fontFamily: 'customfont', background: '#fff' }}>DAY</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                    {timeLeft?.days.length === 1 ? '0' : timeLeft?.days[0]}
                                </div>
                                <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                    {timeLeft?.days.length === 1 ? timeLeft?.days : timeLeft?.days[1]}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ fontSize: isMobile ? '8px' : '12px', display: 'inline-block', width: isMobile ? 'auto' : '40px', textAlign: 'center', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', borderBottom: 'none', fontFamily: 'customfont', background: '#fff' }}>HRS</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                    {timeLeft?.hours.length === 1 ? '0' : timeLeft?.hours[0]}
                                </div>
                                <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                    {timeLeft?.hours.length === 1 ? timeLeft?.hours : timeLeft?.hours[1]}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ fontSize: isMobile ? '8px' : '12px', display: 'inline-block', width: isMobile ? 'auto' : '40px', textAlign: 'center', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', borderBottom: 'none', fontFamily: 'customfont', background: '#fff' }}>MIN</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                    {timeLeft?.minutes.length === 1 ? '0' : timeLeft?.minutes[0]}
                                </div>
                                <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                    {timeLeft?.minutes.length === 1 ? timeLeft?.minutes : timeLeft?.minutes[1]}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div style={{ padding: '12px' }}>
                <div style={{ color: '#000', textAlign: 'center', fontSize: isMobile ? '14px' : '21px', paddingBottom: '4px', borderBottom: 'solid 1px #939393' }}>The Funky Chicken #{tokenId}</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', color: '#939393', marginTop: '6px', fontSize: isMobile ? '14px' : '20px' }}>
                    <div>狀態</div>
                    <div>性別</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#1FD5C2', fontSize: isMobile ? '18px' : '30px' }}>
                    <div style={{ fontWeight: '900', color: props.isEnergy ? '#F27B20' : props.isInCoolDown ? '#1FD5C2' : '#D51F1F' }}>{props.isEnergy ? '精神飽滿中' : props.isInCoolDown ? '冷卻中' : '交配中'}</div>
                    <img src={genderIconMap[props.chickenType]} style={{ height: isMobile ? '15px' : '35px', flexShrink: 0 }} alt="" />
                </div>
            </div>
        </div>
    )
}

export default ChickenCard
