import { Layout } from 'antd';
import "antd/dist/antd.css";
import { useEffect, useState } from 'react';
import { useActiveWeb3React } from './hooks/web3';
import mintExample from './images/mint.gif';
import mintBg from './images/mint-bg.png'
import AppHeader from './components/AppHeader';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useTheFunkyChickenContract } from './hooks/useContract';
import { openNotificationWithIcon } from './helpers/notification';
import { BigNumber, ethers } from 'ethers';
import BorderButton from './components/BorderButton';
import { isMobile } from './utils/userAgent'
import Account from './components/Account';

const styles = {
  layout: {
    backgroundColor: 'transparent',
    backgroundImage: `url(${mintBg})`,
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh'
  }
}

function Home() {
  const { account, chainId } = useActiveWeb3React();
  const [mintCount, setMintCount] = useState(1);
  const [totalSupply, setTotalSupply] = useState(0);
  const [mintPrice, setMintPrice] = useState<BigNumber>();
  const [publicStartTime, setPublicStartTime] = useState(0);
  const [whitelistStartTime, setWhitelistStratTime] = useState(0);
  const [mintButtonDisabled, setMintButtonDisabled] = useState(false)
  const theFunkyChickenContract = useTheFunkyChickenContract()
  useEffect(() => {
    const getContractSetting = async () => {
      if (theFunkyChickenContract) {
        setTotalSupply((await theFunkyChickenContract?.totalSupply()).toNumber());
        setWhitelistStratTime((await theFunkyChickenContract?.whitelistMintStartTime()) * 1000);
        const publicStartTime = (await theFunkyChickenContract.publicMintStartTime()) * 1000
        setPublicStartTime(publicStartTime);
        setMintPrice(publicStartTime >= Date.now() ?
          await theFunkyChickenContract?.whitelistPrice() :
          await theFunkyChickenContract?.chickenPrice()
        );
      }
    }
    if (!account) return
    getContractSetting()
  }, [account, theFunkyChickenContract])
  const addMintCount = () => {
    setMintCount(mintCount + 1);
  }
  const minusMintCount = () => {
    if (mintCount > 1) {
      setMintCount(mintCount - 1);
    }
  }
  const handleMint = async () => {

    if (!account) {
      openNotificationWithIcon('info', 'Please connect your wallet', '')
      return
    }
    if (chainId !== 137 && chainId !== 4) {
      openNotificationWithIcon('info', 'Please switch to polygon network', '')
      return
    }

    if (!theFunkyChickenContract) return

    if (publicStartTime >= Date.now()) {
      if (whitelistStartTime >= Date.now()) {
        openNotificationWithIcon('info', 'Whitelist mint not started yet', "")
        return
      }
      try {
        setMintButtonDisabled(true)
        const tx = await theFunkyChickenContract?.whitelistMint(mintCount, {
          value: mintPrice?.mul(mintCount)
        })
        const receipt = await tx?.wait()
        if (receipt?.status) {
          openNotificationWithIcon('success', 'Mint success!', "")
          setMintCount(1)
          setTotalSupply((await theFunkyChickenContract?.totalSupply()).toNumber());

        }
      } catch (err) {
        setMintButtonDisabled(false)
        // @ts-ignore:next-line
        if (err.code === "INSUFFICIENT_FUNDS") {
          openNotificationWithIcon('error', 'INSUFFICIENT FUND', "You might not have enough fund to perform this opertaion")
          return
        }
        // @ts-ignore:next-line
        if (err.code === 4001) {
          openNotificationWithIcon('error', 'User denied transaction signature', "")
          return
        }
        openNotificationWithIcon('error', 'Error while sending transaction', "")
      }
    } else {
      try {
        setMintButtonDisabled(true)
        const tx = await theFunkyChickenContract?.mint(mintCount, {
          value: mintPrice?.mul(mintCount)
        })
        const receipt = await tx?.wait()
        if (receipt?.status) {
          openNotificationWithIcon('success', 'Mint success!', "")
          setMintCount(1)
          setTotalSupply((await theFunkyChickenContract?.totalSupply()).toNumber());
        }
      } catch (err) {
        setMintButtonDisabled(false)
        // @ts-ignore:next-line
        if (err.code === "INSUFFICIENT_FUNDS") {
          openNotificationWithIcon('error', 'INSUFFICIENT FUND', "You might not have enough fund to perform this opertaion")
          return
        }
        // @ts-ignore:next-line
        if (err.code === 4001) {
          openNotificationWithIcon('error', 'User denied transaction signature', "")
          return
        }
        openNotificationWithIcon('error', 'Error while sending transaction', "")
        return
      }
    }

    setMintButtonDisabled(false)

  }
  return (
    <Layout style={{ ...styles.layout, backgroundPosition: isMobile ? '25% 50%' : 'center' }}>
      <AppHeader />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          color: '#fff',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isMobile ? 'flex-start' : 'center',
          padding: isMobile ? '54px 30px 0' : '0'
        }}
      >
        {isMobile && <Account />}
        <div style={{
          margin: isMobile ? '0' : '0 56px',
          width: isMobile ? '100%' : '25%',
          maxWidth: isMobile ? '100%' : '534px',
          padding: isMobile ? '30px' : '0',
          order: isMobile ? '2' : '',
        }}
        >
          <BorderButton
            width='100%'
            backgroundColor='#FFEFB8'
            padding={isMobile ? '2px' : '12px 10px'}
            style={{ color: '#000' }}
          >
            {totalSupply}/10000 已領養
          </BorderButton>
          <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-between', marginTop: '18px' }}>
            <BorderButton
              width={isMobile ? '50px' : '90px'}
              style={{ height: isMobile ? '50px' : '90px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexShrink: 0 }}
              onClick={minusMintCount}
            >
              <MinusOutlined style={{ fontSize: isMobile ? '20px' : '40px', color: '#000' }} />
            </BorderButton>
            <BorderButton width='100%' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px', color: '#000', fontSize: isMobile ? '20px' : '40px' }}>
              {mintCount}
            </BorderButton>
            <BorderButton
              width={isMobile ? '50px' : '90px'}
              style={{ height: isMobile ? '50px' : '90px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexShrink: 0 }}
              onClick={addMintCount}
            >
              <PlusOutlined style={{ fontSize: isMobile ? '20px' : '40px', color: '#000' }} />
            </BorderButton>
          </div>
          <div style={{ textAlign: 'center', fontSize: isMobile ? '22px' : '30px', margin: '6px 0', fontFamily: 'Gothic A1, sans-serif' }}>
            {mintPrice ? ethers.utils.formatEther(mintPrice.mul(mintCount)) : 0} MATIC
          </div>
          <BorderButton
            width='100%'
            backgroundColor='#FFB85B'
            padding={isMobile ? '' : '2px 10px'}
            style={{ color: '#000' }}
            onClick={handleMint}
            fontSize={isMobile ? '30px' : '36px'}
            disabled={mintButtonDisabled}
          >
            領養
          </BorderButton>
          <div style={{ fontSize: isMobile ? '12px' : '14px', marginTop: '12px' }}>
            白名單者可以Mint上限10隻，但您必須先Mint 2隻後才能馬上mint後面8隻。
          </div>
        </div>
        <img
          src={mintExample}
          style={{
            width: isMobile ? '100%' : '26%',
            maxWidth: isMobile ? '215px' : '635px',
            margin: isMobile ? '15px 0' : '0 56px',
            order: isMobile ? '1' : '',
          }}
          alt=""
        />
      </div>
    </Layout >
  );
}

export default Home;
