import { SupportedChainId } from '../connectors/index'

type AddressMap = { [chainId: number]: string }

export const FUNKY_CHICKEN_ADDRESS: AddressMap = {
    [SupportedChainId.HARDHAT]: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
    // [SupportedChainId.MUMBAI]: '0x2f201Dcc51Dd30B060F1339ed55fAeE5b82F6F38',
    [SupportedChainId.POLYGON]: '0x56E7944b4fe5B72148713853B5ed4A51D8DA593b',
    [SupportedChainId.RINKEBY]: '0x7b998ecC58d184a8641c50cBF22dF698c263d1A3',

}

export const FUNKY_GOLDEN_EGG_ADDRESS: AddressMap = {
    [SupportedChainId.HARDHAT]: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
    // [SupportedChainId.MUMBAI]: '0x74d0D1E02fd0cdd668b83805c68A1055E424de6F',
    [SupportedChainId.POLYGON]: '0xb811E7De209bdB8103C9A146e602Df15888EdBdE',
    [SupportedChainId.RINKEBY]: '0xc3853ae2083B0F33dD793Ad1b0fda7CD3e0B000C',


}

export const FUNKY_EGG_ADDRESS: AddressMap = {
    [SupportedChainId.HARDHAT]: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
    // [SupportedChainId.MUMBAI]: '0x74d0D1E02fd0cdd668b83805c68A1055E424de6F',
    [SupportedChainId.POLYGON]: '0xe752DC227Bb16000CF8AcB314Bf4B3b522223d87',
    [SupportedChainId.RINKEBY]: '0x7691622f5f85976075Ac3129cAa2d2F04Ca4979b',

}