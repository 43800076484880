import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Layout, Modal, Spin, Row, Select } from 'antd';
import CryptoJS from 'crypto-js';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Account from './components/Account';
import AppHeader from './components/AppHeader';
import BorderButton from './components/BorderButton';
import ChickenCard, {
  GenderType,
  StatusType
} from './components/Card';
import ChickenModal from './components/ChickenModal';
import { FUNKY_CHICKEN_ADDRESS } from './constants/address';
import { openNotificationWithIcon } from './helpers/notification';
import { useFunkyEggContract, useTheFunkyChickenContract } from './hooks/useContract';
import { useActiveWeb3React } from './hooks/web3';
import listBg from './images/list-bg.png';
import './styles/chicken-modal.css';
import { isMobile } from './utils/userAgent';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
const { Option } = Select;

const SelectPrefix = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-color: #909090 transparent transparent;
  border-style: solid;
  border-width: 7px 6px 0;
`

export type ChickenProfile = {
  tokenId: number;
  holdingTime: number; //在錢包的時間
  tokenUri: string;
  chickenType: GenderType; //0 母 1 公 2 中性 3 旺盛
  isInCoolDown: boolean; //是否在冷卻中
  sexEndTime?: number; //交配結束時間 timestamp
}

function ListPage() {
  const { account, chainId } = useActiveWeb3React();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isHatchModalVisible, setHatchModalVisible] = useState(false);
  const [activeChicken, setActiveChicken] = useState<null | ChickenProfile>(null)
  const [gender, setGender] = useState<undefined | string>(undefined)
  const [status, setStatus] = useState<undefined | string>(undefined)
  const [isGetEggModalVisible, setIsGetEggModalVisible] = useState(false);
  const [eggBoxCount, setEggBoxCount] = useState(1);

  const [energyChickenList, setEnergyChickenList] = useState<number[]>([])
  const [chickenList, setChickenList] = useState<ChickenProfile[]>([])
  const theFunkyChickenContract = useTheFunkyChickenContract()
  const funkyEggContract = useFunkyEggContract()
  const [activeCardImage, setActiveCardImage] = useState('')
  const [balance, setBalance] = useState(0)
  const [eggBalance, setEggBalance] = useState('0')
  const [isHatchable, setIsHatchable] = useState(false)
  const [isRedeeeming, setIsRedeeming] = useState(false)
  const getChickenList = async () => {
    if (theFunkyChickenContract && account) {
      const originList = await (await theFunkyChickenContract?.getChickenList(account)).map(chicken => {
        return {
          ...chicken,
          sexEndTime: chicken.isInCoolDown ? 0 : Math.floor(Date.now() / 1000) + 24 * 86400 - chicken.holdingTime
        }
      })
      const energyChickenList = []
      const femaleChickenList = originList.filter(chicken => chicken.chickenType === GenderType.FEMALE && !chicken.isInCoolDown)
      const maleChickenList = originList.filter(chicken => (chicken.chickenType === GenderType.MALE && !chicken.isInCoolDown) || chicken.chickenType === GenderType.HORNY)
      const neutralChickenList = originList.filter(chicken => chicken.chickenType === GenderType.NON_BINARY_GENDER && !chicken.isInCoolDown)
      const femaleChickenAmount = femaleChickenList.length;
      const maleChickenAmount = maleChickenList.length;
      const increment = neutralChickenList.length % 2;

      if (femaleChickenAmount > maleChickenAmount) {
        const difference = femaleChickenAmount - (maleChickenAmount + increment)
        for (let i = 0; i < difference; i++) {
          energyChickenList.push(femaleChickenList[i].tokenId)

        }
      } else if (femaleChickenAmount < maleChickenAmount) {
        const difference = maleChickenAmount - (femaleChickenAmount + increment)
        for (let i = 0; i < difference; i++) {
          energyChickenList.push(maleChickenList[i].tokenId)
        }
      } else {
        if (neutralChickenList.length && neutralChickenList.length % 2 === 1) {
          energyChickenList.push(neutralChickenList[0].tokenId)
        }
      }
      setBalance((await theFunkyChickenContract.balanceOf(account)).toNumber())
      setEnergyChickenList(energyChickenList)
      setChickenList(originList)
    }
  }
  const getHatchable = async () => {
    if (theFunkyChickenContract) {
      setIsHatchable((await theFunkyChickenContract.isHatchable()))
    }
  }
  const getEggBalance = async () => {
    if (account && funkyEggContract) {
      setEggBalance(ethers.utils.formatEther((await funkyEggContract.balanceOf(account))))
    }
  }
  useEffect(() => {
    if (!account || !theFunkyChickenContract) return
    getChickenList()
    getHatchable()
    getEggBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, theFunkyChickenContract, funkyEggContract])

  const filterChickenList = (chickenList: ChickenProfile[] | undefined) => {
    if (!chickenList) return []

    return chickenList
      .filter(chicken => gender ? chicken.chickenType === parseInt(gender) : true)
      .filter(chicken => status ? chicken.isInCoolDown === (status === '1') : true)
  }

  const handleClickCard = async (id: number) => {
    setActiveCardImage((await import(`./assets/images/${id}.png`)).default);
    const chicken = chickenList.find(chicken => chicken.tokenId === id)
    if (chicken) {
      setActiveChicken(chicken)
    }
    setIsModalVisible(true);
  }
  const handleCancel = () => {
    setActiveChicken(null)
    setActiveCardImage('')
    setIsModalVisible(false);

  }
  const handleHatch = async () => {
    if (!account) {
      openNotificationWithIcon('info', 'Please connect your wallet', '')
      return
    }
    if (!isHatchable) {
      openNotificationWithIcon('info', 'Not Hatchable yet', '')
      return
    }
    const tx = await theFunkyChickenContract?.autoHatch()
    const receipt = await tx?.wait()
    if (receipt?.status) {
      openNotificationWithIcon('success', 'Hatch successfully!', '你已成功領取雞蛋幣!')
      getChickenList()
      getHatchable()
    }
  }
  const openHatchModal = async () => {
    setHatchModalVisible(true)
  }
  const handleClickConfirm = () => {
    handleHatch()
    setHatchModalVisible(false)
  }
  const cancelHatchModal = () => {
    setHatchModalVisible(false)
  }
  const getAvailableHatchAmount = () => {
    if (!chickenList?.length) return 0;
    const femaleChickenList = chickenList?.filter(chicken => chicken.chickenType === GenderType.FEMALE && !chicken.isInCoolDown)
    const maleChickenList = chickenList?.filter(chicken => chicken.chickenType === GenderType.MALE && !chicken.isInCoolDown)
    const nonBinaryChickenList = chickenList?.filter(chicken => chicken.chickenType === GenderType.NON_BINARY_GENDER && !chicken.isInCoolDown)
    const hornyChickenList = chickenList?.filter(chicken => chicken.chickenType === GenderType.HORNY)
    let hatchTime = chainId === 137 ? 86400 * 24 : 120
    let femaleHatchableAmount = femaleChickenList?.filter(chicken => chicken.holdingTime >= hatchTime).length
    let maleHatchableAmount = maleChickenList?.filter(chicken => chicken.holdingTime >= hatchTime).length
    const nonBinaryChickenHatchableAmount = nonBinaryChickenList?.filter(chicken => chicken.holdingTime >= hatchTime).length
    const hornyHatchableAmount = hornyChickenList.length
    maleHatchableAmount += hornyHatchableAmount
    let additionalAmount = 0
    let hatchableAmount = Math.min(femaleHatchableAmount, maleHatchableAmount)
    if (nonBinaryChickenHatchableAmount % 2 === 0) {
      additionalAmount += nonBinaryChickenHatchableAmount / 2
    } else {
      const canIncrement = Math.abs(femaleHatchableAmount - maleHatchableAmount) === 1 ? 1 : 0
      additionalAmount += Math.floor((nonBinaryChickenHatchableAmount - 1) / 2) + canIncrement
    }
    return hatchableAmount + additionalAmount


  }
  const handleEggeRedeem = async () => {
    if (!account || !chainId) {
      openNotificationWithIcon('info', 'Please connect your wallet', '')
      return
    }
    if (+eggBalance === 0) {
      openNotificationWithIcon('info', 'No egg balance', '')
      return
    }
    if (+eggBalance < eggBoxCount * 24) {
      openNotificationWithIcon('info', 'No enough egg balance', '')
      return
    }
    const receipient = FUNKY_CHICKEN_ADDRESS[chainId]
    setIsRedeeming(true)
    const tx = await funkyEggContract?.transfer(receipient, ethers.utils.parseEther((eggBoxCount * 24).toString()))
    const receipt = await tx?.wait()
    if (receipt?.status) {
      setIsRedeeming(false)
      setEggBoxCount(1)
      getEggBalance()
      const secret = CryptoJS.AES.encrypt(receipt.transactionHash, SECRET_KEY as string).toString();
      openNotificationWithIcon('success', 'Egg Redeem successfully!', '你已成功兌換雞蛋!')

      window.location.href = `https://docs.google.com/forms/d/e/1FAIpQLSc9534No5bwpf6Ey-1liwxaelh49pF3ETuTBiqu7grUHe0HVg/viewform?usp=pp_url&entry.845833537=${receipt.transactionHash}&entry.1571280719=${secret}`;
    }



  }
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppHeader />
      <div style={{ height: isMobile ? '200px' : '300px', background: `url(${listBg}) no-repeat`, backgroundPosition: isMobile ? '0' : 'center', backgroundSize: 'cover' }} />
      <div style={{ background: '#fff', padding: isMobile ? '14px' : '30px 85px' }}>
        <div style={{ display: 'flex', justifyContent: isMobile ? 'strentch' : 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: isMobile ? '100%' : 'auto' }}>
            <div>
              <div style={{ display: isMobile ? 'none' : 'block', color: '#000', fontSize: '28px', fontWeight: 'bold', marginBottom: '8px' }}>
                性別
              </div>
              <Select
                style={{ width: isMobile ? '100%' : '150px', border: '#909090 solid 2px', borderRadius: '10px' }}
                bordered={false}
                suffixIcon={<SelectPrefix />}
                placeholder="搜尋性別"
                defaultValue={gender}
                onChange={(value) => { setGender(value) }}
              >
                <Option value="">所有</Option>
                <Option value='1'>公雞</Option>
                <Option value='0'>母雞</Option>
                <Option value='2'>非二元性別雞</Option>
              </Select>

            </div>
            <div style={{ marginLeft: isMobile ? '0' : '50px', marginTop: isMobile ? '8px' : '0' }}>
              <div style={{ display: isMobile ? 'none' : 'block', color: '#000', fontSize: '28px', fontWeight: 'bold', marginBottom: '8px' }}>
                狀態
              </div>
              <Select
                style={{
                  width: isMobile ? '100%' : '150px',
                  border: '#909090 solid 2px',
                  borderRadius: '10px'
                }}
                bordered={false}
                suffixIcon={<SelectPrefix />}
                placeholder="搜尋狀態"
                defaultValue={status}
                onChange={(value) => { setStatus(value) }}
              >
                <Option value="">所有</Option>
                <Option value='1'>冷卻中</Option>
                <Option value='0'>交配中</Option>
                <Option value={StatusType.ENERGY}>精神飽滿中</Option>
              </Select>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <a href="https://www.metafarm.tw/" target="_blank" rel="noreferrer">
              <BorderButton
                backgroundColor='#F7FF87'
                width='100%'
                padding='5px 30px'
                fontSize='22px'
                style={{ alignSelf: 'flex-start', display: isMobile ? 'none' : 'block' }}

              >
                蛋農官網
              </BorderButton>
            </a>
            <BorderButton
              backgroundColor='#9BE0FF'
              padding='5px 30px'
              fontSize='22px'
              style={{ margin: '0 20px', alignSelf: 'flex-start', display: isMobile ? 'none' : 'block' }}
              onClick={() => { setIsGetEggModalVisible(true) }}
            >
              兌換雞蛋
            </BorderButton>
            <BorderButton
              backgroundColor='#FF5B5B'
              padding='5px 30px'
              fontSize='22px'
              style={{ alignSelf: 'flex-start', display: isMobile ? 'none' : 'block' }}
              onClick={openHatchModal}
            >
              領取雞蛋幣
            </BorderButton>
          </div>
        </div>
        {
          isMobile &&
          <div style={{ padding: '0 30px', marginTop: '8px' }}>
            <Account />
            <BorderButton
              backgroundColor='#FF5B5B'
              width='100%'
              padding='5px 30px'
              fontSize='22px'
              style={{ marginTop: '8px' }}
              onClick={openHatchModal}
            >
              領取雞蛋幣
            </BorderButton>

            <a href="https://www.metafarm.tw/" target="_blank" rel="noreferrer">
              <BorderButton
                backgroundColor='#F7FF87'
                width='100%'
                padding='5px 30px'
                fontSize='22px'
                style={{ marginTop: '8px' }}

              >
                蛋農官網
              </BorderButton>
            </a>
          </div>
        }

        <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 20]} style={{ marginTop: isMobile ? '20px' : '100px' }}>
          {balance ?
            filterChickenList(chickenList)
              ?.map((chicken, index) =>
                <Col span={12} md={12} lg={6} xxl={4} key={index}>
                  <ChickenCard {...chicken} isEnergy={energyChickenList?.includes(chicken.tokenId)} onClick={() => { handleClickCard(chicken.tokenId) }} />
                </Col>
              ) :
            <div>您尚未領養放克雞，趕快領養吧！</div>
          }
        </Row>
      </div>
      <ChickenModal
        visible={isModalVisible}
        onCancel={handleCancel}
        activeCardImage={activeCardImage}
        energyChickenList={energyChickenList}
        chickenData={activeChicken}
      />
      <Modal
        visible={isHatchModalVisible}
        onCancel={cancelHatchModal}
        centered={true}
        footer={null}
        closable={false}
        maskStyle={{ background: 'rgba(0,0,0,0.86)' }}
        wrapClassName="chicken-modal"
      >
        <div style={{ color: '#939393', textAlign: 'center', fontSize: isMobile ? '22px' : '40px', paddingBottom: isMobile ? '10px' : '34px' }}>
          <span>可領取雞蛋幣：</span>
          <span style={{ color: '#000' }}>{getAvailableHatchAmount() * 24}</span>
        </div>
        <div style={{ borderTop: 'solid 2px #939393', paddingTop: isMobile ? '10px' : '34px', display: 'flex' }}>
          <BorderButton
            backgroundColor='#FF5B5B'
            fontSize={isMobile ? '18px' : '32px'}
            width='50%'
            style={{ marginRight: isMobile ? '8px' : '25px' }}
            onClick={handleClickConfirm}
          >
            領取
          </BorderButton>
          <BorderButton
            backgroundColor='#FFB85B'
            fontSize={isMobile ? '18px' : '32px'}
            width='50%'
            style={{ marginLeft: isMobile ? '8px' : '25px' }}
            onClick={cancelHatchModal}
          >
            取消
          </BorderButton>
        </div>

      </Modal>
      <Modal
        visible={isGetEggModalVisible}
        onCancel={() => { setIsGetEggModalVisible(false) }}
        centered={true}
        footer={null}
        closable={false}
        maskStyle={{ background: 'rgba(0,0,0,0.86)' }}
        wrapClassName="chicken-modal"
      >
        <Spin spinning={isRedeeeming} tip='兌換中...'>

          <div style={{ display: 'flex', padding: isMobile ? '0 30px' : '0 70px' }}>
            <BorderButton
              backgroundColor='#fff'
              fontSize={isMobile ? '18px' : '32px'}
              width={isMobile ? '50px' : '90px'}
              style={{ height: isMobile ? '50px' : '90px', flexShrink: 0, }}
              onClick={() => {
                if (eggBoxCount <= 1) return
                setEggBoxCount(eggBoxCount - 1)
              }}
            >
              <MinusOutlined />
            </BorderButton>
            <BorderButton
              backgroundColor='#fff'
              width='100%'
              style={{ margin: '0 8px', flexGrow: 1 }}
            >
              <span style={{ fontFamily: 'customfont', fontSize: isMobile ? '26px' : '45px', fontWeight: '500' }}>{eggBoxCount}/盒</span>
            </BorderButton>
            <BorderButton
              backgroundColor='#fff'
              fontSize={isMobile ? '18px' : '32px'}
              width={isMobile ? '50px' : '90px'}
              style={{ height: isMobile ? '50px' : '90px', flexShrink: 0, }}
              onClick={() => { setEggBoxCount(eggBoxCount + 1) }}
            >
              <PlusOutlined />
            </BorderButton>
          </div>
          <div style={{ textAlign: 'center', margin: '6px 0', color: '#939393', fontSize: isMobile ? '20px' : '42px' }}>
            合計:
            <span style={{ color: '#000', }}>{eggBoxCount * 24}</span>
            <span style={{ color: '#000', fontWeight: 'bold', fontSize: isMobile ? '14px' : '28px' }}>EGG</span>
          </div>
          <div style={{ borderTop: isMobile ? 'solid 2px #dedede' : 'solid 6px #dedede', paddingTop: isMobile ? '10px' : '20px', display: 'flex', justifyContent: 'center' }}>

            <BorderButton
              backgroundColor='#FF5B5B'
              fontSize={isMobile ? '20px' : '32px'}
              width={isMobile ? '100px' : '50%'}
              style={{ marginRight: isMobile ? '10px' : '25px' }}
              onClick={handleEggeRedeem}
            >
              兌換
            </BorderButton>
            <BorderButton
              backgroundColor='#FFB85B'
              fontSize={isMobile ? '20px' : '32px'}
              width={isMobile ? '100px' : '50%'}
              style={{ marginLeft: isMobile ? '10px' : '25px' }}
              onClick={() => { setIsGetEggModalVisible(false) }}
            >
              取消
            </BorderButton>

          </div>
        </Spin>

      </Modal>

    </Layout >
  );
}

export default ListPage;
