import { Modal, ModalProps } from 'antd'
import '../styles/chicken-modal.css'
import { isMobile } from '../utils/userAgent'
import { genderIconMap, TimeLeft } from './Card';
import { ChickenProfile } from '../ListPage'
import { useEffect, useState } from 'react';
import idMap from '../constants/idMap.json'
let myMap = new Map(Object.entries(idMap));


export interface Props extends ModalProps {
    activeCardImage: string;
    energyChickenList: number[]
    chickenData: ChickenProfile | null
}

const ChickenModal = (props: Props) => {
    const calculateTimeLeft = (): TimeLeft | null => {
        const endTime: number = props.chickenData?.sexEndTime || 0
        let difference = endTime - Math.floor(Date.now() / 1000);
        if (difference <= 0) return null

        return {
            days: Math.floor(difference / (60 * 60 * 24)).toString(),
            hours: Math.floor((difference / (60 * 60)) % 24).toString(),
            minutes: Math.floor((difference / 60) % 60).toString(),
            seconds: Math.floor((difference) % 60).toString()
        };
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [tokenId, setTokenId] = useState<string | undefined>("");
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });
    useEffect(() => {
        if (props.chickenData?.tokenId) {
            setTokenId(myMap.get(props.chickenData?.tokenId.toString()))
        }
    }, [props.chickenData?.tokenId])
    return (
        <Modal
            visible={props.visible}
            onCancel={props.onCancel}
            footer={null}
            closable={false}
            centered={true}
            maskStyle={{ background: 'rgba(0,0,0,0.86)' }}
            wrapClassName="chicken-modal"
        >
            {
                props.chickenData &&
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: isMobile ? '165px' : '45%', alignSelf: 'center', position: 'relative', flexShrink: 0 }}>
                        <img src={props.activeCardImage} style={{ width: '100%' }} alt="" />
                        {
                            !props.chickenData.isInCoolDown && typeof props.chickenData.sexEndTime === 'number' && props.chickenData.sexEndTime > Math.floor(Date.now() / 1000) && !props.energyChickenList.includes(props.chickenData.tokenId) &&
                            <div style={{ position: 'absolute', bottom: '14px', left: '0', width: '100%', display: 'flex', justifyContent: 'space-between', padding: isMobile ? '0 5px' : '0 10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ fontSize: isMobile ? '8px' : '12px', display: 'inline-block', width: isMobile ? 'auto' : '40px', textAlign: 'center', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', borderBottom: 'none', fontFamily: 'customfont', background: '#fff' }}>DAY</div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                            {timeLeft?.days.length === 1 ? '0' : timeLeft?.days[0]}
                                        </div>
                                        <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                            {timeLeft?.days.length === 1 ? timeLeft?.days : timeLeft?.days[1]}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ fontSize: isMobile ? '8px' : '12px', display: 'inline-block', width: isMobile ? 'auto' : '40px', textAlign: 'center', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', borderBottom: 'none', fontFamily: 'customfont', background: '#fff' }}>HRS</div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                            {timeLeft?.hours.length === 1 ? '0' : timeLeft?.hours[0]}
                                        </div>
                                        <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                            {timeLeft?.hours.length === 1 ? timeLeft?.hours : timeLeft?.hours[1]}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ fontSize: isMobile ? '8px' : '12px', display: 'inline-block', width: isMobile ? 'auto' : '40px', textAlign: 'center', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', borderBottom: 'none', fontFamily: 'customfont', background: '#fff' }}>MIN</div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                            {timeLeft?.minutes.length === 1 ? '0' : timeLeft?.minutes[0]}
                                        </div>
                                        <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: isMobile ? 'solid 2px #000' : 'solid 4px #000', fontFamily: 'customfont', background: '#fff', textAlign: 'center', lineHeight: isMobile ? '21px' : '32px', fontSize: isMobile ? '17px' : '30px' }}>
                                            {timeLeft?.minutes.length === 1 ? timeLeft?.minutes : timeLeft?.minutes[1]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{ marginLeft: isMobile ? '10px' : '32px', padding: '30px 0', flexGrow: 1 }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', borderBottom: 'solid 2px rgba(223, 223, 223)', paddingBottom: isMobile ? '8px' : '20px' }}>
                            <div style={{ fontSize: isMobile ? '14px' : '24px', lineHeight: isMobile ? '1.5' : '1' }}>The Funcky Chicken #{tokenId}</div>
                            {<img src={genderIconMap[props.chickenData.chickenType]} height={isMobile ? 22 : 36} alt="" />}
                        </div>
                        <div style={{ color: '#939393', fontSize: isMobile ? '16px' : '40px', marginTop: isMobile ? '8px' : '30px', textAlign: 'center' }}>狀態</div>
                        <div
                            style={{
                                color:
                                    props.energyChickenList?.includes(props.chickenData.tokenId)
                                        ? '#F27B20'
                                        : props.chickenData.isInCoolDown
                                            ? '#1FD5C2'
                                            : '#D51F1F',
                                marginBottom: '4px',
                                textAlign: 'center',
                                fontSize: isMobile ? '35px' : '55px',
                                lineHeight: '1',
                                fontWeight: '900'
                            }}
                        >
                            {
                                props.energyChickenList?.includes(props.chickenData.tokenId) ? '精神飽滿中' :
                                    props.chickenData.isInCoolDown ? '冷卻中' : '交配中'
                            }
                        </div>
                        <div style={{ fontSize: isMobile ? '12px' : '20px', textAlign: 'center' }}>
                            {

                                props.energyChickenList?.includes(props.chickenData.tokenId) ? '趕緊給牠找個伴吧..' :
                                    props.chickenData.isInCoolDown ? '趕緊給牠找個伴吧..' : '請耐心等待..'
                            }
                        </div>
                    </div>
                </div>

            }
        </Modal>
    )
}


export default ChickenModal;