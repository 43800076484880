import {
  Routes,
  Route
} from "react-router-dom";
import Home from './Home'
import ListPage from './ListPage'
import "antd/dist/antd.css";
import "./style.css";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/list" element={<ListPage />} />
    </Routes>
  )
}

export default App;
