import styled from 'styled-components'
import { ReactNode } from 'react'

export interface Props {
    children: ReactNode
    backgroundColor?: string
    fontSize?: string
    padding?: string
    width?: string
    style?: object
    disabled?: boolean
    onClick?: () => void
}

const BorderButton = styled.button<Pick<Props, 'backgroundColor' | 'fontSize' | 'padding' | 'width' | 'disabled'>>`
    display: inline-block;
    width: ${props => props.width || 'auto'};
    border: solid 5px #000;
    border-radius: 16px;
    text-align: center;
    background-color: ${props => props.backgroundColor || '#fff'};
    font-weight: bold;
    position: relative;
    color: #000;
    font-size: ${props => props.fontSize || '24px'};
    padding: ${props => props.padding || '0 0'};
    box-shadow: 3px 3px 0px black;
    &:hover {
        cursor: pointer;
    }
`

const Button = (props: Props) => {
    return (
        <BorderButton
            onClick={props.onClick}
            backgroundColor={props.backgroundColor}
            padding={props.padding}
            fontSize={props.fontSize}
            width={props.width}
            style={props.style}
            disabled={props.disabled}
        >
            {props.children}
        </BorderButton>
    )
}

export default Button