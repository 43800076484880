import { Modal, Typography } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import BorderButton from '../components/BorderButton';
import { injected } from '../connectors';
import { getEllipsisTxt } from '../helpers/formatters';
import { useActiveWeb3React } from '../hooks/web3';
import MetaMaskIcon from '../images/metamask.png';
import { isMobile } from '../utils/userAgent';

declare let window: any;

interface Props {

}
const { Text } = Typography
const styles = {
    modal: {
        borderRadius: "12px",
    }
    ,
    iconWrapper: {
        alignItems: "center",
        justifyContent: "center",
    }
}
const Box = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #e7eaf3;
    :hover {
        cursor: pointer;
        border: 1px solid #40a9ff;
    }
`
const Account = (props: Props) => {
    const { activate, account } = useActiveWeb3React()
    const [isModalOpen, setModalOpen] = useState(false);
    if (account) {
        return (
            <BorderButton
                onClick={() => { setModalOpen(true) }}
                backgroundColor="#FFB85B"
                width={isMobile ? '100%' : '350px'}
                padding={isMobile ? '5px 30px' : '5px 0'}
                fontSize={isMobile ? '22px' : '28px'}
            >
                {getEllipsisTxt(account)}
            </BorderButton>
        )
    }
    return (<>
        <BorderButton
            onClick={() => { setModalOpen(true) }}
            backgroundColor="#FFB85B"
            width={isMobile ? '100%' : '350px'}
            padding={isMobile ? '5px 30px' : '5px 0'}
            fontSize={isMobile ? '22px' : '28px'}
        >
            Connect Wallet
        </BorderButton>
        <Modal
            title="連接錢包"
            style={styles.modal}
            visible={isModalOpen}
            footer={null}
            maskStyle={{ background: 'rgba(0,0,0,0.86)' }}
            onCancel={() => { setModalOpen(false) }}
            bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
            }}>
            {
                window.ethereum ?
                    <Box onClick={() => {
                        activate(injected, undefined, true)
                            .catch(err => { console.log(err) })
                            .finally(() => { setModalOpen(false) })
                    }}>
                        <Text>Metamask</Text>
                        <div style={styles.iconWrapper}>
                            <img src={MetaMaskIcon} alt="Icon" height="24px" width="24px" />
                        </div>
                    </Box>
                    :
                    (isMobile ?
                        <a href="https://metamask.app.link/dapp/mint.thefunkychicken.io/" target="_blank" rel="noreferrer">
                            <Box>
                                <Text>安裝或開啟Metamask</Text>
                                <div style={styles.iconWrapper}>
                                    <img src={MetaMaskIcon} alt="Icon" height="24px" width="24px" />
                                </div>
                            </Box>
                        </a> :
                        <a href="https://metamask.io" target="_blank" rel="noreferrer">
                            <Box>
                                <Text>安裝Metamask</Text>
                                <div style={styles.iconWrapper}>
                                    <img src={MetaMaskIcon} alt="Icon" height="24px" width="24px" />
                                </div>
                            </Box>
                        </a>
                    )
            }
        </Modal>
    </>
    )
}

export default Account
