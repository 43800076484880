import Account from "./Account"
import facebook from '../images/facebook.png'
import '../header.css';

const AppHeader = () => {
    return (
        <div className="navbar-2 w-nav">
            <div className="container-2 w-container">
                <a href="/" className="brand w-nav-brand" >
                    <img
                        src="https://uploads-ssl.webflow.com/61dbec77efbfb619c213ec91/61dece78e350d139338739b7_logo.png"
                        loading="lazy" alt="" className="main-logo" />
                </a>
                <nav role="navigation" className="w-nav-menu">
                    <a href="https://www.facebook.com/TFKChicken2022/" target="_blank"
                        className="nav-link-sticky w-inline-block" rel="noreferrer">
                        <img
                            src={facebook}
                            loading="lazy" width="58" alt="" className="social-media-logo" />
                    </a>
                    <a
                        href="https://twitter.com/TFKChicken2022" target="_blank" className="nav-link-sticky-2 w-inline-block" rel="noreferrer"><img
                            src="https://uploads-ssl.webflow.com/61dbec77efbfb619c213ec91/61e3969212077770846cb944_Asset%204.png"
                            loading="lazy" width="58" alt="" className="social-media-logo" />
                    </a>
                    <a
                        href="https://discord.gg/thefunkychicken2022" target="_blank"
                        className="nav-link-sticky w-inline-block" rel="noreferrer">
                        <img
                            src="https://uploads-ssl.webflow.com/61dbec77efbfb619c213ec91/61e39691f040da112fe0ecbf_Asset%201.png"
                            loading="lazy" width="58" alt="" className="social-media-logo" />
                    </a>
                    <a href="https://opensea.io/collection/the-funky-chicken" target="_blank"
                        className="nav-link-sticky w-inline-block" rel="noreferrer">
                        <img
                            src="https://uploads-ssl.webflow.com/61dbec77efbfb619c213ec91/61e4d50e931ba51ac64e1b77_opensea.png"
                            loading="lazy" width="58" alt="" className="social-media-logo" />
                    </a>
                    <Account />
                </nav>
            </div>
        </div>

    )
}

export default AppHeader